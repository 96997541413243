import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import useFiltersAndOrder from './useFiltersAndOrder';
import { ProductsState } from '../reducer/productsList/products.state';
import { getDataFromStorage, NameStorage } from '../utils/services/localStorage.service';

const getKeyListProducts = (section: keyof ProductsState | string): keyof ProductsState => {
  const { asPath } = useRouter();
  const isResult = asPath.includes('resultados');
  const existAreaOfInterest = !!getDataFromStorage<number>(NameStorage.areaOfInterest);
  const [sectionName, setSectionName] = useState<keyof ProductsState>(null);
  const { doTheSearch, order, filter: filters } = useFiltersAndOrder();

  useEffect(() => {
    if ((order || Object.keys(filters).length) && doTheSearch) {
      setSectionName('filters');
      return;
    }

    if (!section && isResult) {
      setSectionName('results');
      return;
    }

    if (isResult) {
      setSectionName(`${section}Result` as unknown as keyof ProductsState);
      return;
    }

    if (['book', 'ebook'].includes(section) && existAreaOfInterest) {
      setSectionName(`${section}WithArea` as unknown as keyof ProductsState);
      return;
    }

    setSectionName(section as unknown as keyof ProductsState);
  }, [section, filters, order, doTheSearch]);

  return sectionName;
};

export default getKeyListProducts;
