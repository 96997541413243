import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import speakingUrl from 'speakingurl';

import getKeyListProducts from '../../../hooks/useGetKeyListProducts';
import useScrollBarPage from '../../../hooks/useScrollbarPage';
import useSearch from '../../../hooks/useSearch';
import useStoreProducts from '../../../reducer/productsList/storeProducts';
import { Area, Book } from '../../../graphqlTypes';
import { Correlation } from '../../../models/common.model';
import useFiltersAndOrder from '../../../hooks/useFiltersAndOrder';
import constants from '../../../utils/constants';
import reducerListing from '../../../reducer/listing/reducerListing';
import {
  getDataFromStorage,
  NameStorage,
  removeDataInStorage,
} from '../../../utils/services/localStorage.service';

const Filters = dynamic(() => import('../Filters/Filters'));
const ProductListing = dynamic(() => import('./ProductListing'));
const Link = dynamic(() => import('../link/Link'));

interface ListingProps {
  type?: string;
  category?: string;
  title?: string;
  listCurrentProducts?: Book[];
  area?: Area;
  isAreaPage?: boolean;
  totalCurrentProducts?: number;
  search?: string;
}

const Listing = ({
  type,
  category,
  title,
  listCurrentProducts,
  area,
  isAreaPage,
  totalCurrentProducts: totalCurrentProductsProps,
  search,
}: ListingProps): JSX.Element => {
  const { filter } = useFiltersAndOrder();
  const { push } = useRouter();
  const [totalCurrentProducts, setTotalCurrentProducts] = useState<number>(
    totalCurrentProductsProps || 0,
  );
  const [state, setState] = useReducer(reducerListing, {
    type,
    searching: false,
    firstSearchResults: false,
    prevSearch: null,
    category,
  });
  const { setSearch } = useSearch();
  const { isTheActualPageSave } = useScrollBarPage();

  const sectionName = getKeyListProducts(category);
  const setInitialStateBySection = useStoreProducts((store) => store.setInitialStateBySection);

  const isFilteringArea = (): boolean => {
    let filterArea = filter?.['Áreas'] && [...filter?.['Áreas']];

    if (filterArea) {
      filterArea = filterArea.filter((x) => x);
    }

    return filterArea?.length > 0;
  };

  const setListingLoading = useCallback((loadingState: boolean): void => {
    setState({ type: 'set_state', listing: { loading: loadingState } });
  }, []);

  const switchSearch = (): void => {
    const searchType = state.alternativeSearchType;
    if (searchType === 0) {
      removeDataInStorage(NameStorage.areaOfInterest);
      removeDataInStorage(NameStorage.areaOfInterestName);
    }
  };

  useEffect(() => {
    setInitialStateBySection(sectionName, { books: listCurrentProducts }, isTheActualPageSave());
  }, [sectionName]);

  useEffect(() => {
    const updateState: Record<string, unknown> = {};

    updateState.category = category;

    // type
    updateState.type = type;

    setState({ type: 'set_state', listing: { ...updateState } });
  }, [category, type]);

  return (
    <div className="row listing">
      {search ? (
        <p className="counter toolsBlock">
          {`${totalCurrentProducts} resultados encontrados por la búsqueda`}
          {search}

          <Link
            onClick={(): void => {
              const areaName = getDataFromStorage<string>(NameStorage.areaOfInterestName);

              if (!areaName || !constants.sectionWithAreasOfInterest.includes(state.category)) {
                push(state.category ? `/${Correlation[state.category]}` : '/');
                return;
              }

              const slug = speakingUrl(areaName.replace(/, /g, '.'), { custom: ['.'] });
              setSearch(null);
              push(state.category ? `/${Correlation[state.category]}/${slug}` : '/');
            }}
          >
            Cancelar búsqueda
          </Link>

          {((!!state.alternativeSearchText && state.alternativeSearchType !== 0) ||
            state.alternativeSearchType === 0) &&
            isFilteringArea() && (
              <Link onClick={switchSearch}>{`Buscar en ${state.alternativeSearchText}`}</Link>
            )}
        </p>
      ) : null}

      <Filters category={state.category} type={state.type} search={search} />

      <ProductListing
        title={title}
        type={state.type}
        search={search}
        listProducts={listCurrentProducts}
        category={category}
        setListingLoading={setListingLoading}
        area={area?.nameEsFriendly}
        isAreaPage={isAreaPage}
        setTotalCurrentProducts={setTotalCurrentProducts}
      />
    </div>
  );
};

export default React.memo(Listing);
