import ActionListing, { ListingState } from './actionListing';

const reducerListing = (state: ListingState, action: ActionListing): ListingState => {
  switch (action.type) {
    case 'set_state': {
      return {
        ...state,
        ...action.listing,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducerListing;
